@import '~@amzn/awsui-design-tokens/polaris.scss';
@import '~@amzn/awsui-components-react/sass/constants';
@import '~@amzn/awsui-components-react/sass/mixins';
@import 'app-layout';
@import 'header';

.custom-home__header {
  background-color: $awsui-aws-squid-ink;
  padding-bottom: 30px;
}

.custom-home__header-title {
  color: $awsui-white;

  .awsui-text-large h1 {
    padding-bottom: 0;
  }
}

.custom-home__category,
.custom-home__header-title p {
  color: $awsui-grey-300;
  font-weight: lighter;
}

@media (min-width: 992px) {
  .custom-home__header-sidebar {
    margin-bottom: 1rem;
  }

  .custom-home__sidebar {
    margin-top: -60px;
  }
}

.custom-awsui-util-pt-xxxl {
  padding-top: 40px;
}

.custom-home__header-title > * {
  max-width: 700px;
}

.custom-home-image__placeholder:before {
  content: 'X';
  display: block;
  background-color: $awsui-grey-150;
  background-color: var(--awsui-color-background-layout-main);
  color: $color-background-container-content;
  text-align: center;
  font-size: 400px;
  line-height: 400px;
}

.custom-home__external {
  white-space: nowrap;
}

.custom-home__external > :last-child {
  margin-left: 0.5rem;
}

ul.custom-list-separator {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    border-top: 1px solid $color-border-divider-default;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.custom-home-main-content-area .custom-home-section-header {
  font-weight: $font-weight-normal;
  @include responsive-font-size-4x;
}

.awsui-polaris-dark-mode {
  .custom-home__header {
    background-color: black;
  }

  .custom-home__header-title {
    color: $color-text-heading-default;
  }
}

// CHARTIST STYLES

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.2rem;
  line-height: 1;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #dd6b10;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
  fill: #dd6b10;
}

@import './AppFooterFont';
@import '~@amzn/awsui-components-react/sass/constants';

.console-nav-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 3.5rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: $font-stack;
    background-color: #232f3f;
    color: var(--awsui-color-text-home-header-default, $awsui-white);
    box-sizing: border-box;
}

.footer-container-right {
    > span {
        margin: 0 0 0 2rem;
    }
}

.footer-link {
    color: $awsui-grey-300;
    &:hover {
        color: orange;
        cursor: pointer;
        text-decoration: none;
    }
}

.copyright-notice {
    color: $awsui-grey-400;
    font-size: 12px;
    font-weight: 400;

    @media (max-width: 768px) {
        display: none;
    }
}

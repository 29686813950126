@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
@import '~@amzn/awsui-components-react/sass/constants.scss';
@import '~@amzn/awsui-components-react/sass/mixins.scss';
@import '~@amzn/awsui-components-react/index';

.awsui {

  .site-body {
    padding-top: 60px;

    .awsui-util-header-counter {
      margin-left: 0.2em;
    }

    .action-stripe {
      
      awsui-button {
        margin-top: -0.3em;
        margin-left: 1em;
      }

      .title {
        display: -webkit-inline-flex;
        display: inline-flex;
      }
    }
  }

}